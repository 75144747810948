import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { analyticsStore, ocorrenciaStore } from '@/utils/store-accessor';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import GenericListItemsCardComponent from '@/components/GenericListItemsCardComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import SyngooFaleConosco from '@/components/SyngooFaleConosco.vue';
let HistoricoDeEnvio = class HistoricoDeEnvio extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                text: 'Enviado em',
                value: 'created_at',
                table: 'ocorrencia',
                show: true,
                type: 'date',
            },
            {
                text: 'Enviado por',
                value: 'full_name',
                table: 'responsavel',
                show: true,
                type: 'str',
            },
            {
                text: 'Entidade',
                table: 'ocorrencia',
                value: 'entidade_id',
                type: 'str',
                show: true,
            },
            {
                text: 'Tipo',
                table: 'syngoo_mensagem',
                value: 'name',
                type: 'str',
                show: true,
            },
            {
                text: 'Mensagem',
                table: 'ocorrencia',
                value: 'observacao',
                type: 'str',
                show: true,
            },
        ];
        this.selectPeriodDate = null;
    }
    get totals() {
        return analyticsStore.totals;
    }
    async buscar() {
        const filters = {};
        filters['ocorrencia.created_at::DATE'] = {
            type: 'timestamp',
            value: [this.selectPeriodDate[0], this.selectPeriodDate[1]],
        };
        filters['ocorrencia.syngoo_mensagem_id'] = {
            type: 'is_not_null',
        };
        analyticsStore.setDefaultFilters(filters);
        await this.doSearchTotals();
        await analyticsStore.executeSearchItems();
    }
    async doSearchItems(queryIn) {
        return await ocorrenciaStore.getOcorrenciasQuery(queryIn);
    }
    async doSearchTotals() {
        await analyticsStore.getAnalyticsSyngooHistoricoEnvio({
            data_inicial: this.selectPeriodDate[0],
            data_final: this.selectPeriodDate[1],
        });
    }
    async mounted() {
        analyticsStore.initAnalytics();
        analyticsStore.setFnSearchItems(ocorrenciaStore.getOcorrenciasQuery);
        analyticsStore.setHeaders(this.headers);
        // tela que tem defaultFilter nao precisa ter o buscar() aqui
    }
};
HistoricoDeEnvio = __decorate([
    Component({
        components: {
            GenericListItemsCardComponent,
            GenericListTotalCardComponent,
            OpenNewTabComponent,
            PeriodSelectorComponent,
            TextExpandableComponent,
            SyngooFaleConosco,
        },
    })
], HistoricoDeEnvio);
export default HistoricoDeEnvio;
