var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-0 pt-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('period-selector-component',{attrs:{"prop-title":"Data do envio"},model:{value:(_vm.selectPeriodDate),callback:function ($$v) {_vm.selectPeriodDate=$$v},expression:"selectPeriodDate"}})],1),_c('v-col',[_c('v-btn',{staticClass:"primary",on:{"click":function($event){return _vm.buscar()}}},[_vm._v(" buscar ")])],1)],1),_c('v-row',[(_vm.totals.por_mensagem)?_c('v-col',[_c('GenericListTotalCardComponent',{attrs:{"prop-title":"Por mensagem","prop-items":_vm.totals.por_mensagem}})],1):_vm._e()],1),(_vm.totals.por_mensagem)?_c('v-row',[_c('v-col',[_c('GenericListItemsCardComponent',{attrs:{"propTableName":"list_historico_envios"},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.created_at))+" ")]}},{key:"item.full_name",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',[_vm._v(" "+_vm._s(value)+" ")]):_c('span',[_vm._v(" ENVIO AUTOMÁTICO ")])]}},{key:"item.entidade_id",fn:function(ref){
var item = ref.item;
return [_c('open-new-tab-component',{attrs:{"propObjectLabel":((item.entidade_nome) + " - " + (item.entidade_tipo)),"propObjectId":item.entidade_id,"propToolName":item.entidade_tipo}})]}},{key:"item.observacao",fn:function(ref){
var item = ref.item;
return [_c('text-expandable-component',[_vm._v(" "+_vm._s(item.observacao)+" ")])]}}],null,true)})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }